import React from "react"
import Base from "./Base"

import ComponentParser from "../helpers/ComponentParser"

const FeaturesSubPageTemplate = ({ pageContext }) =>  {
  const {contentFiles, contentParsed} = pageContext

  return (
    <Base context={pageContext}>
      {/* <Helmet>
        <script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/v2.js"></script>
      </Helmet> */}
      {/* {pageContext.isFrontPage ? <ComponentParser key={pageContext} content={pageContext.blocks} /> : <section className="section-bottom"><div className="inner-div"><div className="page-content" dangerouslySetInnerHTML={{ __html: pageContext.content }} /></div></section>} */}
      { <ComponentParser key={pageContext} content={pageContext.blocks} contentParsed={contentParsed} files={contentFiles} />}
    </Base>
  )
}

export default FeaturesSubPageTemplate